export const CommonStrings = {
  en: {
    success: 'Success',
    error: 'Error',
    users: 'Users',
    male: 'Male',
    female: 'Female',
    today: 'Today',
    dateRange: 'Date Range',
    thisWeek: 'This Week',
    thisMonth: 'This Month',
    thisYear: 'This Year',
    selectFile: 'Select File',
    settings: 'Settings',
    notifications: 'Notifications',
    commonErrorMessage: 'Something went wrong.',
    forgotPassword: 'Forgot Password',
    faq: 'FAQS',
    resetPassword: 'Reset Password',
    changePassword: 'Change Password',
    max10: 'MAX 10 MB',
    pageNotFoundHeader: 'OOPS! PAGE NOT FOUND',
    pageNotFoundSubHeader: `WE ARE SORRY! BUT THE PAGE YOU REQUESTED \n WAS NOT FOUND`,
    contactUs: 'Contact Us',
    supportFile: 'Supported files:.pdf,.txt,.docx,.doc,.docm,.dotx',
    contactUsForms: 'Contact Us Forms',
    contactUsFormSubmission: 'Contact Us Submission',
    contactUsMessages: 'Contact Us Messages',
    contactUsSpamMessages: 'Contact Us Spam Messages',
    entities: 'Entities',
    entityObjects: 'Entity Objects',
    entitySubmission: 'Entity Submission',
    fileUploadText: 'Click or drag file to this area to upload',
    png: 'PNG',
    jpg: 'JPG',
    jpeg: 'JPEG',
    pdf: 'PDF',
    html: 'HTML',
    wordDoc: 'Word (.doc)',
    wordDocx: 'Word (.docx)',
    excel: 'Excel (xls)',
    excelX: 'Excel (xlsx)',
    download: 'Download',
    imageTypes: 'Image Types',
    fileTypes: 'File Types',
    phoneNoMinLengthError: 'must have 10 digits',
    emailInvalidError: 'Invalid Email!',
    spaceError: 'No spaces allowed',
    yes: 'yes',
    no: 'no',
    dropDownValueMinError: 'At least 1 choice is required',
    versions: 'Versions',
    notifyUsers: 'Notify-Users',
    deleteConfirmationText: 'Are you sure you want to delete this ?',
    fieldKeyConfirmationMsg:
      'You have changed the field key. You may lose the old data, are you sure you want to update?',
    choiceValueError: 'Choice value is already exist',
    map: 'Map',
    fileLibrary: 'File Library',
    goToMainPage: 'Go to main page',
    sessionExpired:
      ' Your session has expired. You will be redirected to login page after 10 seconds.',
    internal: 'Internal',
    external: 'External'
  },
  es: {
    appName: 'MI bazi',
    success: 'Éxito',
    error: 'Error',
    users: 'Usuarias',
    settings: 'Ajustes',
    notifications: 'Notificaciones',
    commonErrorMessage: 'Algo salió mal.',
    forgotPassword: 'Has olvidado tu contraseña',
    faq: 'Preguntas frecuentes',
    resetPassword: 'Restablecer la contraseña',
    changePassword: 'Cambia la contraseña',
    pageNotFoundHeader: '¡UPS! PÁGINA NO ENCONTRADA',
    pageNotFoundSubHeader: `¡LO SENTIMOS! PERO LA PÁGINA QUE SOLICITÓ \n NO FUE ENCONTRADA`,
    contactUs: 'Contacta con nosotras',
    contactUsForms: 'Formularios de contacto',
    contactUsFormSubmission: 'Contáctenos',
    contactUsMessages: 'Contáctenos Mensajes',
    contactUsSpamMessages: 'Contáctenos Mensajes de spam',
    entities: 'Entidades',
    entityMessages: 'Mensajes de entidad',
    entitySubmission: 'Presentación de la entidad',
    fileUploadText: 'Haga clic o arrastre el archivo a esta área para cargarlo',
    png: 'PNG',
    jpg: 'JPG',
    jpeg: 'jpeg',
    pdf: 'PDF',
    html: 'HTML',
    wordDoc: 'Palabra (.doc)',
    wordDocx: 'Palabra (.docx)',
    excel: 'excel (xls)',
    excelX: 'Excel (xlsx)',
    download: 'Descargar',
    imageTypes: 'Tipos de imagen',
    fileTypes: 'Tipos de archivo',
    phoneNoMinLengthError: 'debe tener 10 dígitos',
    emailInvalidError: '¡Email inválido!',
    spaceError: 'No se permiten espacios',
    yes: 'si',
    no: 'no',
    dropDownValueMinError: 'Se requiere al menos 1 opción',
    versions: 'Versiones',
    notifyUsers: 'Notificar a las usuarias',
    deleteConfirmationText: '¿Estás segura de que quieres eliminar esto?',
    fieldKeyConfirmationMsg:
      'Ha cambiado la clave de campo. Puede perder los datos antiguos, ¿está seguro de que desea actualizarlos?',
    choiceValueError: 'El valor de elección ya existe',
    googleMap: 'Mapa de Google',
    fileLibrary: 'Biblioteca de archivos'
  }
}
