import LocalizedStrings from 'react-localization'
import { clientStrings } from '../../clients/localization/strings'
import { DashboardStrings } from '../../dashboard/localization/strings'
import { employeesStrings } from '../../employees/localization/strings'
import { UserStrings } from '../../users/localization/strings'
import { consultantsStrings } from '../../consultants/localization/strings'
import { timesheetStrings } from '../../timesheet/localization/strings'
import { CommonStrings } from '../localization/strings'
import { settingsStrings } from '../../settings/localization/strings'
import { projectStrings } from '../../projects/localization/strings'
import { leavesAndAttendanceStrings } from '../../leaves-and-attendance/localization/strings'
import { interviewStrings } from '../../exit-interview/localization/strings'
import { policyStrings } from '../../policy-and-announcement/localization/strings'
import { externalAppsStrings } from '../../external-apps/localization/strings'
import { buildStrings } from '../../builds/localization/strings'

const stringsArr = [
  CommonStrings,
  DashboardStrings,
  UserStrings,
  employeesStrings,
  interviewStrings,
  clientStrings,
  consultantsStrings,
  timesheetStrings,
  settingsStrings,
  policyStrings,
  projectStrings,
  leavesAndAttendanceStrings,
  externalAppsStrings,
  buildStrings
]

const messages: { [key: string]: { [key: string]: string } } = {}

for (let i = 0; i < stringsArr.length; i++) {
  Object.keys(stringsArr[i]).forEach((key: string) => {
    const languageObj = stringsArr[i]
    messages[key] = {
      ...messages[key],
      ...languageObj[key as keyof typeof languageObj]
    }
  })
}

const Strings = new LocalizedStrings(messages)

export default Strings
