export const buildStrings = {
  en: {
    project: 'Project',
    builds: 'Builds',
    managerName: 'Manager',
    deliveryManager: 'Delivery Manager',
    techLead: 'Tech Lead',
    qaLead: 'QA Lead',
    startEndDate: 'Start/End Date',
    totalExpectedHours: 'Total Hours/Expected Hours',
    upcomingMilestone: 'Upcoming Milestone',
    status: 'Status',
    addBuild: 'Add Build',
    userType: 'User Type',
    manageBuild: 'Manage Build',
    clientName: 'Client Name',
    clientEmail: 'Client Email',
    url: 'URL',
    platform: 'Platform',
    employee: 'Employee',
    urlRequired: 'URL is required',
    addBuildSuccess: 'Build Added Successfully',
    addBuildUserSuccess: 'Build User Added Successfully',
    addBuildUrlSuccess: 'Build URL Added Sucessfully',
    searchUserByName: 'Search User By Name',
    searchByUrl: 'Search By Url',
    userTypeRequired: 'User Type is required',
    enableBuilds: 'Enable Builds',
    enableBuild: 'Enable Build',
    deleteBuildUserSuccess: 'Build User Deleted Successfully',
    platformRequired: 'Platform is Required',
    iOS: 'iOS',
    web: 'Web',
    android: 'Android',
    clientNameRequired: 'Client Name is required',
    projectRequired: 'Project is Required',
    addURL: 'Add URL',
    save: 'Save',
    enterURL: 'Enter URL',
    addUser: 'Add User',
    selectUserType: 'Select User Type',
    selectEmployee: 'Select Employee',
    enterClientName: 'Enter Client Name',
    enterClientEmail: 'Enter Client Email',
    internalSmall: 'internal',
    externalSmall: 'external',
    deleteUser: 'Delete User',
    deleteURL: 'Delete URL',
    manageUsers: 'Manage Users',
    internalUsers: 'Internal Users',
    externalUsers: 'External Users',
    manageURLs: 'Manage URLs'
  },
  es: {}
}
