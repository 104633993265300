export const consultantsStrings = {
  en: {
    consultantName: 'Consultant Name',
    consultants: 'Consultants',
    projects: 'Projects',
    addConsultants: 'Add Consultant',
    editConsultants: 'Edit Consultant',
    updateConsultants: 'Update Consultant',
    placeHolderConsSearch: 'Search Consultant...',
    consultantNumberRequred: 'Consultant number is required.',
    emailMobiRequired: 'Email must be from @mobisoftinfotech.com domain',
    deleteConsultants: 'Delete Consultants',
    profileImage: 'Profile Image',
    consultantNo: 'Consultant No',
    dateOfBirth: 'Date of birth',
    bloodGroup: 'Blood Group',
    skypeContact: 'Skype Contact',
    Address: 'Address',
    emailInvalid: 'The email is invalid!',
    email: 'Email',
    totalHours: 'Total Hours',
    valueRange: 'Number cannot be negative',
    contactNo1: 'Contact No. 1',
    contactNo2: 'Contact No. 2',
    joiningDate: 'Joining Date',
    startDate: 'Start Date',
    endDate: 'End Date',
    select: 'Select',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    other: 'other',
    selectOptions: 'Select Options',
    roles: 'Roles',
    admin: 'Admin',
    auth: 'Auth',
    skills: 'Skills',
    designation: 'Designation',
    contractor: 'Contractor',
    onRoll: 'on Roll',
    access: 'Access',
    reportingManager: 'Reporting Manager',
    addEmployeeSuccess: 'Employee Added Successfully!',
    backspace: 'Backspace'
  },
  es: {}
}
