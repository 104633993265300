export const DashboardStrings = {
  en: {
    description: 'Are you sure you want Logout',
    logout: 'Logout',
    dashboard: 'Dashboard',
    selectLanguage: 'Select Language',
    languages: 'Languages',
    english: 'English',
    titleNameNoSpace: "Title Name can't start or end with space",
    spanish: 'Spanish',
    es: 'es',
    addTimeSheetHeader: 'Add New Timesheet',
    searchByTitleOrProjectPlace: 'Search by Project or Task title...',
    durationValidation: 'Duration cannot be more than 4 hours',
    back: 'Back',
    en: 'en',
    selectProjectPlaceHolder: 'Select Project',
    titlePlaceHolder: 'Enter Title',
    descriptionPlaceHolder: 'Enter Description',
    durationFormatValidation: 'Invalid duration format.',
    minTitle: 'Title must be at least 5 characters long!',
    maxTitle: 'Title must be at most 81 characters long!',
    updateLanguage: 'Update Language',
    addTimesheetSuccess: 'Timesheet added Successfully!',
    editTimesheetSuccess: 'Timesheet edited Successfully!',
    builds: 'Builds'
  },
  es: {
    description: 'Estas segura que quieres cerrar sesión',
    logout: 'Cerrar sesión',
    dashboard: 'Tablero',
    selectLanguage: 'seleccione el idioma',
    languages: 'Idiomas',
    spanish: 'española',
    es: 'es',
    updateLanguage: 'Actualizar idioma'
  }
}
